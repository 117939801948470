.half {
    
    &_block {

        &.flipped {
            background-color: white;
        }

        @include media-breakpoint-down(md) {
            .wrap+.wrap { margin-top: 30px; }
        }

        @include media-breakpoint-up(lg) {
            .wrap:not(.full_item) {
                @include make-col(1,2);
            }
        }
    }

}